<template>
    <div class="projects">
     


        <div class="grid" v-for="project in all_projects" v-bind:key="project.id" >
        <FlipCard>
            <template slot="front">
                <h1>{{project.name}}</h1>
                <img class="frame responsive" style="height:300px;" :src="project.image" />
            </template>
    <template 
} slot="back">
     <p style="color: black;"> {{project.description}}</p>
    </template>
  </FlipCard>
    <div class="button-grid">
       <div><a :href="project.github" > <font-awesome-icon style="font-size: 48px; color: white;" :icon="['fab', 'git-alt']" /></a></div> 
      <a :href="project.live"><img style="height:48px;" :src="project.icon"></a>
        </div>
</div>
    </div>
</template>







<style>


.button-grid {
width: 300px;
display: flex;
justify-content: space-evenly;
align-content: space-evenly;
z-index: 0;
}
.grid {
display: flex;
flex-direction: column;

align-items: center;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.projects {
  display: grid; 
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 105px;
  grid-auto-rows: 1fr; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 25px 5px; 
  grid-template-areas: 
    ". ."; 
}



.flip-container {
align-items: center;
margin-right: 0px;
height: 350px;
color: black;
}
.info-card{
height: 350px;

}
.front.info-card.body {
}
@media only screen and (min-width: 1400px) { 
   .projects {
   grid-template-columns: 1fr 1fr 1fr; 
   grid-template-areas: 
    ". . ."; 
   }

}
@media only screen and (max-width: 921px) {
   .projects {
   grid-template-columns: 1fr; 
   
    grid-template-areas: 
    ". "; 
   }
   .flip-container {
   height: 225px;
   }
 }

@media only screen and (max-width: 450px) { 
   .projects {
   margin-bottom: 10px;
   grid-template-columns: 1fr; 
    grid-template-areas: 
    "."; 
   }
  .frame-responsive {
    padding-bottom: 20px;
  }
  .button-grid {
    z-index: 110;

  }
  .flip-container {
align-items: center;
margin-right: 0px;
height: 350px;


}
.info-card{
height: 350px;

}
}
</style>


<script>


import FlipCard from '../components/FlipCard.vue'
import projects from '../assets/projects.json';
    export default {  
    name: 'Projects',
     components: {
            
            FlipCard
  },
    data() {
        return {
            all_projects: projects
        }
    }

      }
</script>