<template>
<div>
<footer class="footer">
 
  

<nav class="menu">

  <input type="checkbox" href="#" class="menu-open" name="menu-open" id="menu-open"/>
  
  <label class="menu-open-button" for="menu-open">
    <font-awesome-icon :icon="['fa', 'id-card']" />

  </label>
  
  <a href="mailto:im@tedteah.org" class="menu-item">   <font-awesome-icon :icon="['fa', 'envelope-open-text']" />  </a>
  <a class="menu-item" href="https://github.com/squirrellypenguin" ><font-awesome-icon :icon="['fab', 'github']" /></a>
   <a class="menu-item" href="https://www.linkedin.com/in/ted-teah-461515171/" >     <font-awesome-icon :icon="['fab', 'linkedin']" /> </a>
  
  
  
</nav>


<!-- filters -->
<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="shadowed-goo">
          
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
          <feGaussianBlur in="goo" stdDeviation="3" result="shadow" />
          <feColorMatrix in="shadow" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2" result="shadow" />
          <feOffset in="shadow" dx="1" dy="1" result="shadow" />
          <feComposite in2="shadow" in="goo" result="goo" />
          <feComposite in2="goo" in="SourceGraphic" result="mix" />
      </filter>
      <filter id="goo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
          <feComposite in2="goo" in="SourceGraphic" result="mix" />
      </filter>
    </defs>
</svg>

</footer>
</div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style lang="scss">


//vars
$fg:#e83e8c;
$bg:#000;
$pi:3.14;

//config
$menu-items:4;
$open-distance:105px;
$opening-angle:$pi*2;

body{
  background:$bg;
  color:white;
  text-align:center;
}
a{
  color:inherit;
}
h1, h2, h3, h4{
  margin:0;
  margin-bottom:10px;
  margin-top:10px;
}
h1{
  font-size:3em;
}
%goo{
  filter:url('#shadowed-goo');
  // debug 
  //background:rgba(255,0,0,0.2);
}
%ball{
  background:$fg;
  border-radius:100%;
  width:80px;
  height:80px;
  margin-left:-40px;
  position:absolute;
  top:20px;
  color:white;
  text-align:center;
  line-height:80px;
  transform:translate3d(0,0,0);
  transition:transform ease-out 200ms;
}
.menu-open{
  display:none;
}
.menu-item{
  @extend %ball;
  padding-left: 15px;
}
.hamburger{
  $width:25px;
  $height:3px;
  width:$width;
  height:$height;
  background:black;
  display:block;
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-$width/2;
  margin-top:-$height/2;
  transition:transform 200ms;
}
$hamburger-spacing:8px;
.hamburger-1{
  transform:translate3d(0,-$hamburger-spacing,0);
}
.hamburger-2{
  transform:translate3d(0,0,0);
}
.hamburger-3{
  transform:translate3d(0,$hamburger-spacing,0);
}
.menu-open:checked+.menu-open-button{
  .hamburger-1{
    transform:translate3d(0,0,0) rotate(45deg); 
  }
  .hamburger-2{
    transform:translate3d(0,0,0) scale(0.1,1);
  }
  .hamburger-3{
    transform:translate3d(0,0,0) rotate(-45deg); 
  }
}
.menu{
  @extend %goo;
  $width:640px;
  $height:150px;
  position:absolute;
  left: 0;
  margin-top: 44px;
  margin-left:60px;
  padding-top:30px;
  padding-left:0px;
  width:$width;
  height:$height;
  box-sizing:border-box;
  font-size:32px;
  text-align:left;
}


.menu-item{
  &:hover{
    background:white;
    color:$fg;
  }
  @for $i from 1 through $menu-items{
    &:nth-child(#{$i+2}){
      transition-duration:180ms;
    }
  }
}

.menu-open-button{
  @extend %ball;
  z-index:2;
  transition-timing-function:cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition-duration:400ms;
  transform:scale(1.1,1.1) translate3d(0,0,0);
  cursor:pointer;
  padding-left: 12px;
}
.menu-open-button:hover{
  transform:scale(1.2,1.2) translate3d(0,0,0);
}
.menu-open:checked+.menu-open-button{
  transition-timing-function:linear;
  transition-duration:200ms;
  transform:scale(0.8,0.8) translate3d(0,0,0);
}

.menu-open:checked~.menu-item{
  transition-timing-function:cubic-bezier(0.165, 0.840, 0.440, 1.000);
  @for $i from 1 through $menu-items{

    &:nth-child(#{$i+2}){
      transition-duration:90ms+(80ms*$i);
      transform:translate3d((80px*$i),0,0);
    }
  }
}





.flexbox-wrapper{
    margin-top: -8px;
    
    display: flex;
     justify-content: space-between;
     background-color: rgba(255, 255, 255, 0);
    height: 88px;
}
.footer {
  
  bottom: 10px;
  position: fixed;
  width: 100vw;
}
a :hover {
    color: red;
}
.svg-inline--fa a:hover {
    color: red;
}
.contact-buttons {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 52px;
   ;
background-color: red;
    padding-left: 25px;
  

}
@media only screen and (max-width: 1450px) { 
    body {
        height: 100vh;
        }
.flexbox-wrapper{
   
    display: flex;
     justify-content: space-between;
   
    height: 85px;
}
.contact-buttons {
    margin-top: 10px;
    margin-right: 0px;
    font-size: 52px;
    margin-bottom: 0px;
    
   
}
}
@media only screen and (max-width: 950px) { 
.contact-buttons {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 36px;
    margin-bottom: 10px;
    
   
}
.flexbox-wrapper{
    margin-top: 0px;
    display: flex;
     justify-content: space-between;
   
    height: 80px;
}
}

@media only screen and (max-width: 450px) { 
.contact-buttons {
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 20px;
    padding-left: 10px;
    font-size: 24px;
    
   
}
.flexbox-wrapper{
    margin-top: 0px;
    display: flex;
     justify-content: space-between;
    
    height: 60px;
}
.menu-open-button {
font-size:24px;
}
%ball{
  background:$fg;

  border-radius:100%;
  width:60px;
  height:60px;
  margin-left:-54px;
  position:absolute;
  top:50px;
  color:white;
  text-align:center;
  line-height:60px;
  transform:translate3d(0,0,0);
  transition:transform ease-out 200ms;
}
}
</style>