<template>
          <div class="font">
    <h2>Full-Stack Development</h2>
      <div class="container">
    <div class="circle skill-block1">

    <p >Understanding a problem is the first step towards a solution.</p>


    </div>
     <div class="circle skill-block4">

    
<p>Technology provides an infrastructure of tools.</p>


    </div>
    <div class="skill-block2">
    <div class="skill-icon-block" >   
    <div id="nested">

      <font-awesome-icon :icon="['fab', 'css3-alt']" />
        <font-awesome-icon :icon="['fab', 'html5']" />
          <font-awesome-icon :icon="['fab', 'node']" />
            <font-awesome-icon :icon="['fab', 'react']" />
              <font-awesome-icon :icon="['fab', 'vuejs']" />
              <font-awesome-icon :icon="['fab', 'angular']" />
                <font-awesome-icon :icon="['fab', 'sass']" />
                  <font-awesome-icon :icon="['fab', 'js']" />
                    <font-awesome-icon :icon="['fab', 'python']" />
                    <font-awesome-icon :icon="['fab', 'php']" />
                      <font-awesome-icon :icon="['fab', 'linux']" />
                        <font-awesome-icon :icon="['fa', 'gem']" />
                        <font-awesome-icon :icon="['fab', 'docker']" />
                  <font-awesome-icon :icon="['fab', 'bootstrap']" />
                                    <font-awesome-icon :icon="['fab', 'git-alt']" />
           <font-awesome-icon :icon="['fab', 'digital-ocean']" />
   <font-awesome-icon :icon="['fab', 'linode']" />
   <font-awesome-icon :icon="['fab', 'wordpress']" />

    </div>

    </div>
    </div>
    <div style="z-index:3;" class="skill-block3">
   
        <p class="banner">
      A comprehensive understanding of the infrastructure provides a robust toolkit to draw from when iterating through a problem.</p>
      <p   >Let's get to  <router-link style="color:#e83e8c" to="/projects">work</router-link> on it! </p>
        </div>
  </div>
  </div>
</template>
<style >
.font {
  color: white;
}
.shape {
  shape-outside: ellipse(100px 200px at 50% 50%);
  clip-path: ellipse(100px 200px at 50% 50%);
  width: 200px;
  height: 200px;
  float: left;
  opacity: .2;
  margin-top: 20px;
  margin-right: 50px;;
  background-color: purple;
}

.element {
	shape-outside: inset(50px 50px 50px 0px);
  background-color: #ddd;
  /* clip-path: inset(0 0 10px 0); */
  /* background-color: red; */
  width: 200px;
  height: 245px;
  float: left;
}
.container {
font-size: 36px;
font-family: 'Poppins';
    
    min-width: 350px;
    display: grid; 
    grid-auto-columns: 1fr min-content auto; 
    grid-template-columns: 1fr 1fr min-content auto; 
    grid-template-rows: 1fr 1fr 1fr min-content auto; 
    gap: 0px 0px; 
    grid-template-areas: 
      "a a"
      "d b"
      "c c"; 
  }
  .skill-block1 { grid-area: a;
    align-self: center; 
}
  .skill-block2 { grid-area: b;
    align-self: stretch; 
}
  .skill-block3 { grid-area: c;
    align-self: start; 
}
.skill-block3 p.banner {
text-align:left;
}
  .skill-block4 { grid-area: d;
    align-self: center; 
}
  .skill-icon-block {
      font-size: 64px;
         
      align-self: center; 
    }
.svg-inline--fa {
  margin-right: 15px;
}


@media only screen and (max-width: 768px) { 

.container {
font-size: 24px;
font-family: 'Poppins';
    min-height: 500px;
    min-width: 350px;
    display: grid; 
    grid-auto-columns: 1fr min-content auto; 
    grid-template-columns: 1fr 1fr min-content auto; 
    grid-template-rows: 1fr 1fr 1fr min-content auto; 
    gap: 0px 0px; 
    grid-template-areas: 
      "a a"
      "d b"
      "b b"
      "c c"; 
  }
}
@media only screen and (max-width: 450px) { 
   body {
   background-color: blue;
   }
.container {
font-size: 20px;
font-family: 'Poppins';
    
    min-width: 350px;
    
    display: grid; 
    grid-auto-columns: 1fr min-content auto; 
    grid-template-columns: 1fr 1fr ; 
    grid-template-rows: 1fr 1fr  1fr min-content auto; 
    gap: 0px 0px; 
    grid-template-areas: 
      "a a"
      "b b"
      "c c"
      "d d"; 
  }
   .skill-icon-block {
      font-size: 36px;
   }
     .skill-block1 { grid-area: a;
    align-self: center; 
    margin-top: -30px;
}
  .skill-block2 { grid-area: b;
    align-self: stretch;
     margin-top: -30px; 
}
  .skill-block3 { grid-area: c;
    align-self: center; 
    margin-top: -10px;
    
}
  .skill-block4 { grid-area: d;
    align-self: center; 
    display: none;
}
}
</style>
<script>

    export default {  
    name: 'Skills',
    components: {
            
    }


      }
</script>