<template>
  <div class="example" id="app" >
   
    <div class="example" id="nav">
      <Header/>

      
    </div>
    
    <div id="page-wrap" class="content">
    <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
  
}
</script>

<style lang="scss">
 /* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

html body {
   margin:0 auto;
      background-image: url("https://res.cloudinary.com/duvzhauq9/image/upload/v1624735111/zoltan-tasi-jjv1OW1T2bI-unsplash_pfjrkf.jpg");
}

#nav {
  background-color: rgba(0, 0, 0, 0);
  
}
.bm-burger-button .line-style {
   background-color: #e83e8c;
   
  margin-top: -15px;
}
.bm-menu {
  background-color: white !important;
height: 100%;
}
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline-offset: -1px;

}

@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#app {
  -ms-overflow-style: none; 
   scrollbar-width: none;  
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh !important;
}

#nav {
  padding: 30px;
height:75px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #e83e8c;
    }
  }

}


</style>
