<template>
<div class="sizing">
<div class="card card-body" style="width: 38rem;">
<div class="graphic card-img-top">
<h1 class="contact">Say hi!</h1>
<p class="contact">Please drop me a note with any questions or inquiries.</p>
  <a class="contact" href="https://github.com/squirrellypenguin" ><font-awesome-icon :icon="['fab', 'github']" /></a>
   <a class="contact" href="https://www.linkedin.com/in/ted-teah-461515171/" >     <font-awesome-icon :icon="['fab', 'linkedin']" /> </a>
  
  
  
</div>
  <form class=" contact-form" @submit.prevent="sendEmail">
    <label>Name</label>
    <input type="text" class="form-conrol" name="user_name">
    <label>Email</label>
    <input type="email" name="user_email">
    <label>Message</label>
    <textarea rows="8"  name="message"></textarea>
    <input type="submit" value="Send">
  </form>
 </div> 
  </div>
</template>
<style lang="postcss" scoped>
.contact {
  margin-top:50px;
  color: #151515;
}
.card-body {
  margin-left: 5px;
  display:  grid;
  grid-template-columns: 2fr 3fr;
}
.contact-form {
  display:flex;
  flex-direction: column;
}
.sizing {
  height: 60vh;
  padding-left: 25%;
  padding-top: 10%;
}

.bm-menu element {
	perspective: 15000px;
	overflow: hidden;
	height: 100vh;
}
 
.animation {
  padding-bottom: 100px;
display: inline;

}
@media only screen and (min-width: 1400px) { 
.sizing {
  padding-left: 35%;
  padding-top: 5%;

}


}
@media only screen and (max-width: 921px) {
 
 }

@media only screen and (max-width: 450px) { 
.card.cardbody {
  grid-auto-columns: 1fr; 
margin-bottom: 415px;
}
.graphic {
  display: none;
}
 
.sizing {
padding-left: 0px;

}
}
</style>
<script>
import emailjs from 'emailjs-com';
import config from "../../config";
export default {
  methods: {
    sendEmail: (e) => {
        console.log(config)
      emailjs.sendForm(config.emailjs.serviceID, config.emailjs.templateID, e.target, config.emailjs.userID)
        .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
        }, (error) => {
            console.log('FAILED...', error);
        });
    }
  }
}
</script>