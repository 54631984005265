<template>
  <div class="header">
<div><router-link to="/">
<img src="https://res.cloudinary.com/duvzhauq9/image/upload/v1624739850/path57_q7ayxl.png" />
</router-link>
</div>
    <ScaleDown :closeOnNavigation="true" right>
             <a class="navbar-brand"><router-link to='/'>Home</router-link></a>
    
      <router-link to="/projects">Projects</router-link>
 <router-link to="/skills">Skills</router-link>
  <router-link to="/contact">Contact</router-link>
  <a href="https://drive.google.com/file/d/13Q5Fi-Mawfj8IhuYJU9AG63ei6MDuQj3/view?usp=sharing">Resume</a>
    </ScaleDown>



  </div>
</template>

<script>
import { ScaleDown,  } from 'vue-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`
  export default {
    name: 'Header',
    components: {
      ScaleDown,
      
    }
  }
</script>

<style lang="scss">
.header {
  margin-top: -25px;
  
    display: flex;
    background-color: rgba(0, 0, 0, 0);

}
.head-logo {
  z-index: 1;
}

</style>